import React from 'react'
import * as styles from './styles.module.scss'

const Component = ({ direction = 'right', color = 'black' }) => {
  const directionClass = direction === 'left' ? styles.left : styles.right
  const colorClass = color === 'white' ? styles.white : ''
  return (
    <div className={`${styles.arrow} ${directionClass} ${colorClass} `}>
      <div className={styles.bar} />
      <div className={styles.head} />
    </div>
  )
}

export default Component
