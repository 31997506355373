import React from 'react'
import ImageWrap from 'components/ImageWrap'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Block = ({ content }) => {
  const { title, image } = content

  return (
    <div className='pad-v-standard'>
      <Inview className='container grid-12 fade-in up'>
        <div className={`${styles.inner}`}>
          <div className={styles.image}>
            <ImageWrap image={image} />
          </div>
        </div>
      </Inview>
    </div>
  )
}

export default Block
