import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Grid3Thumbs from 'components/Grid3Thumbs'
import ImageWrap from 'components/ImageWrap'
import Inview from 'components/Inview'
import MarkdownWrap from 'components/MarkdownWrap'
import ButtonLink from 'components/ButtonLink'
import * as styles from './styles.module.scss'

const Block = ({ content, data, locale }) => {
  const { pretitle, title, body, image, buttonLink } = content

  const partners = data?.partners?.nodes
  const partnersLocale = partners?.map((item) => item.frontmatter[locale])

  const grid3Thumbs = {
    items: partnersLocale
      .map((item) => {
        return {
          title: item.name,
          subtitle: item.jobTitle,
          subtitle2: item.jobTitle2,
          image: item.image,
          link: `/partners/${item.slug}/`,
        }
      })
      .slice(0, 4),
  }

  return (
    <div className='block pad-v-standard'>
      <div className='container'>
        <Inview className={`${styles.title} fade-in up`}>
          {pretitle && <h5>{pretitle}</h5>}
          <h2>{title}</h2>
        </Inview>
        {body && (
          <Inview className={`body centered fade-in up`}>
            <MarkdownWrap body={body} />
          </Inview>
        )}
        <div className={styles.gridWrap}>
          <Grid3Thumbs content={grid3Thumbs} isExcerpt={true} />
        </div>
        {buttonLink && (
          <Inview className='button-wrap margin-top-more centered fade-in up'>
            <ButtonLink content={buttonLink} />
          </Inview>
        )}
      </div>
    </div>
  )
}

export default function Data({ content, locale }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          partners: allMarkdownRemark(
            filter: { frontmatter: { en: { templateKey: { eq: "partner" } } } }
            sort: { fields: frontmatter___en___order, order: ASC }
          ) {
            nodes {
              frontmatter {
                en {
                  order
                  slug
                  name
                  jobTitle
                  jobTitle2
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                zh {
                  order
                  slug
                  name
                  jobTitle
                  jobTitle2
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => <Block content={content} data={data} locale={locale} />}
    />
  )
}
