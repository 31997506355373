import React from 'react'
import ImageWrap from 'components/ImageWrap'
import LinkWrap from 'components/LinkWrap'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Component = ({ content, isExcerpt }) => {
  const { items } = content
  const isExcerptClass = isExcerpt ? styles.excerpt : ''
  return (
    <div className={`grid-24 ${styles.grid} ${isExcerptClass}`}>
      {items.map((item, i) => (
        <Item content={item} key={i} />
      ))}
    </div>
  )
}

export default Component

const Item = ({ content }) => {
  const { title, subtitle, subtitle2, image, link } = content
  return (
    <LinkWrap className={`${styles.item} zoom-parent`} to={link}>
      <Inview className='fade-in up'>
        <div className='zoom smaller'>
          <ImageWrap image={image} />
        </div>
        <div className={styles.text}>
          <h5 className='bold'>{title}</h5>
          <h6>{subtitle}</h6>
          <h6>{subtitle2}</h6>
        </div>
      </Inview>
    </LinkWrap>
  )
}
