import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import PersonProfile from 'blocks/PersonProfile/template'
// import * as styles from './styles.module.scss'

const Block = ({ data, locale }) => {
  const founders = data?.founders?.nodes
  const foundersLocale = founders?.map((item) => item.frontmatter[locale])

  const blocks = foundersLocale.map((item) => ({
    pretitle: item.title,
    title: item.name,
    body: item.bio,
    image: item.image,
  }))

  return blocks.map((block, i) => (
    <PersonProfile content={block} isBiggerTitle={true} key={i} />
  ))
}

export default function Data({ content, locale }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          founders: allMarkdownRemark(
            filter: { frontmatter: { en: { templateKey: { eq: "founder" } } } }
            sort: { fields: frontmatter___en___order, order: ASC }
          ) {
            nodes {
              frontmatter {
                en {
                  order
                  slug
                  name
                  title
                  bio
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                zh {
                  order
                  slug
                  name
                  title
                  bio
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => <Block content={content} data={data} locale={locale} />}
    />
  )
}
