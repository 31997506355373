import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Grid2 from 'components/Grid2'
import Inview from 'components/Inview'
import MarkdownWrap from 'components/MarkdownWrap'
import ButtonLink from 'components/ButtonLink'
import * as styles from './styles.module.scss'

const Block = ({ content, data, locale }) => {
  const { pretitle, title, body, buttonLink } = content
  const projects = data?.projects?.nodes
  const projectsLocale = projects?.map((item) => item.frontmatter[locale])

  const grid2 = {
    items: projectsLocale
      .filter((item) => item.projectCats.includes('featured'))
      .map((item) => {
        return {
          title: item.title,
          subtitle: item.location,
          image: item.image,
          link: `/projects/${item.slug}/`,
        }
      }),
    // .slice(0, 4)
  }

  return (
    <div className='block pad-v-standard'>
      <div className='container'>
        <Inview className={`${styles.title} fade-in up`}>
          {pretitle && <h5>{pretitle}</h5>}
          <h2>{title}</h2>
        </Inview>
        {body && (
          <Inview className={`body centered fade-in up`}>
            <MarkdownWrap body={body} />
          </Inview>
        )}
        <div className={styles.gridWrap}>
          <Grid2 content={grid2} />
        </div>
        {buttonLink && (
          <Inview className='button-wrap margin-top-more centered fade-in up'>
            <ButtonLink content={buttonLink} />
          </Inview>
        )}
      </div>
    </div>
  )
}

export default function Data({ content, locale }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          projects: allMarkdownRemark(
            filter: { frontmatter: { en: { templateKey: { eq: "project" } } } }
            sort: { fields: frontmatter___en___order, order: ASC }
          ) {
            nodes {
              frontmatter {
                en {
                  order
                  slug
                  title
                  location
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                  projectCats
                }
                zh {
                  order
                  slug
                  title
                  location
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                  projectCats
                }
              }
            }
          }
        }
      `}
      render={(data) => <Block content={content} data={data} locale={locale} />}
    />
  )
}
