import React from 'react'
import Inview from 'components/Inview'
import MarkdownWrap from 'components/MarkdownWrap'
import * as styles from './styles.module.scss'

const Block = ({ content }) => {
  const { subtitle, body, isNoCollapse } = content
  const isNoCollapseClass = isNoCollapse ? 'no-collapse' : ''
  return (
    <Inview className={`pad-v-standard ${isNoCollapseClass} fade-in up`}>
      <div className='container'>
        <div className={styles.text}>
          <div className='border smaller no-top' />
          {subtitle && <h5>{subtitle}</h5>}
          {body && (
            <div className={`body ${styles.body}`}>
              <MarkdownWrap body={body} />
            </div>
          )}
        </div>
      </div>
    </Inview>
  )
}

export default Block
