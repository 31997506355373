import React from 'react'
import Grid2Text from 'components/Grid2Text'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Block = ({ content }) => {
  const { pretitle, title } = content
  return (
    <div className={`block pad-v-standard no-collapse bg-shade`}>
      <Inview className='container fade-in up'>
        <div className='grid-24'>
          <div className={styles.title}>
            {pretitle && <h5>{pretitle}</h5>}
            <h2>{title}</h2>
          </div>
        </div>
        <div className={styles.gridWrap}>
          <Grid2Text content={content} />
        </div>
      </Inview>
    </div>
  )
}

export default Block
