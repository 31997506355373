import React from 'react'
import MarkdownWrap from 'components/MarkdownWrap'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Block = ({ content }) => {
  const {
    pretitle,
    title,
    subtitle,
    body,
    isDark = false,
    isNoBottom = false,
  } = content
  const isDarkClass = isDark ? 'bg-dark' : ''
  const isNoBottomClass = isNoBottom ? 'top-only' : ''
  const isNoBorderBottomClass = isNoBottom ? 'no-bottom' : ''
  const borderColorClass = isDark ? 'white' : ''
  return (
    <div
      className={`block pad-v-standard ${isNoBottomClass} first-add-v-pad ${isDarkClass}`}
    >
      <Inview className='container fade-in up'>
        <div className={styles.text}>
          {pretitle && <h5>{pretitle}</h5>}
          <h2>{title}</h2>
          {subtitle && <h5>{subtitle}</h5>}
          <div
            className={`border ${isNoBorderBottomClass} ${borderColorClass}`}
          />
          {body && (
            <div className={`body ${styles.body}`}>
              <MarkdownWrap body={body} />
            </div>
          )}
        </div>
      </Inview>
    </div>
  )
}

export default Block
