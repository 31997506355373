import React from 'react'
import BannerImageDark from 'blocks/BannerImageDark/template'
import BannerTextOnly from 'blocks/BannerTextOnly/template'
import BannerVideo from 'blocks/BannerVideo/template'
import BannerWrapped from 'blocks/BannerWrapped/template'
import ButtonWrap from 'blocks/ButtonWrap/template'
import ContactForm from 'blocks/ContactForm/template'
import ImageInner from 'blocks/ImageInner/template'
import More from 'blocks/More/template'
import TextCentered from 'blocks/TextCentered/template'
import TextGrid from 'blocks/TextGrid/template'
import TextImage from 'blocks/TextImage/template'
import TextImageFounders from 'blocks/TextImageFounders/template'
import TextMapCentered from 'blocks/TextMapCentered/template'
import TextVideoCentered from 'blocks/TextVideoCentered/template'
import AzotelsIntro from '/src/blocks/_dynamic/AzotelsIntro/template'
import AzotelsSlideshow from '/src/blocks/_dynamic/AzotelsSlideshow/template'
import FoundersGroup from '/src/blocks/_dynamic/FoundersGroup/template'
import PartnersIntro from '/src/blocks/_dynamic/PartnersIntro/template'
import PartnersSlideshow from '/src/blocks/_dynamic/PartnersSlideshow/template'
import ProjectsIntro from '/src/blocks/_dynamic/ProjectsIntro/template'
import ProjectsFiltered from '/src/blocks/_dynamic/ProjectsFiltered/template'
import Footer from 'components/Footer'
import * as styles from './styles.module.scss'

const components = {
  bannerImageDark: BannerImageDark,
  bannerTextOnly: BannerTextOnly,
  bannerVideo: BannerVideo,
  bannerWrapped: BannerWrapped,
  buttonWrap: ButtonWrap,
  contactForm: ContactForm,
  imageInner: ImageInner,
  more: More,
  textCentered: TextCentered,
  textGrid: TextGrid,
  textImage: TextImage,
  textImageFounders: TextImageFounders,
  textMapCentered: TextMapCentered,
  textVideoCentered: TextVideoCentered,
  azotelsIntro: AzotelsIntro,
  azotelsSlideshow: AzotelsSlideshow,
  foundersGroup: FoundersGroup,
  partnersIntro: PartnersIntro,
  partnersSlideshow: PartnersSlideshow,
  projectsIntro: ProjectsIntro,
  projectsFiltered: ProjectsFiltered,
}

const ContentType = ({ pageId, content, locale, layoutProps }) => {
  if (!locale) locale = 'en'
  let { blocks } = content

  return (
    <div className={`${styles.page} page`}>
      {blocks?.map((block, i) => {
        if (components[block.type]) {
          return React.createElement(components[block.type], {
            pageId,
            content: block,
            locale,
            layoutProps,
            key: i,
          })
        }
      })}
      <Footer locale={locale} />
    </div>
  )
}

export default ContentType
