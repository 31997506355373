import React, { useState, useEffect } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Grid2 from 'components/Grid2'
import Inview from 'components/Inview'
import LineLink from 'components/LineLink'
import * as styles from './styles.module.scss'

const Block = ({ content, data, locale }) => {
  const [activeProjectCat, setActiveProjectCat] = useState('')
  const [activeProjects, setActiveProjects] = useState([])

  const projects = data?.projects?.nodes
  const projectsLocale = projects?.map((item) => item.frontmatter[locale])
  const projectCats = data?.projectCats?.nodes
  const projectCatsLocale = projectCats?.map((item) => item.frontmatter[locale])

  const updateActiveProjects = (slug) => {
    // if (slug === activeProjectCat) return
    setActiveProjectCat(slug)
    let newActiveProjects
    if (slug === 'all') {
      newActiveProjects = projectsLocale
    } else {
      newActiveProjects = projectsLocale.filter((item) =>
        item.projectCats.includes(slug)
      )
    }
    setActiveProjects([])
    setTimeout(() => setActiveProjects(newActiveProjects), 1)
  }

  useEffect(() => {
    updateActiveProjects('all')
  }, [])

  const grid2 = {
    items: activeProjects.map((item) => {
      return {
        title: item.title,
        subtitle: item.location,
        image: item.image,
        link: `/projects/${item.slug}/`,
      }
    }),
  }

  const strings = {
    en: {
      all: 'All',
    },
    zh: {
      all: '全部',
    },
  }
  const tr = strings[locale]

  return (
    <div className='block pad-v-standard'>
      <div className='container'>
        <Inview className={`${styles.filterWrap} fade-in up`}>
          <div className={styles.inner}>
            <Filter
              content={{ slug: 'all', title: tr.all }}
              activeProjectCat={activeProjectCat}
              updateActiveProjects={updateActiveProjects}
            />
            {projectCatsLocale.map((item, i) => (
              <Filter
                content={item}
                key={i}
                activeProjectCat={activeProjectCat}
                updateActiveProjects={updateActiveProjects}
              />
            ))}
          </div>
        </Inview>
        <div className={styles.gridWrap}>
          <Grid2 content={grid2} />
        </div>
      </div>
    </div>
  )
}

const Filter = ({ content, activeProjectCat, updateActiveProjects }) => {
  const { slug, title } = content
  const activeClass = activeProjectCat === slug ? 'active' : ''
  return (
    <h5
      className={` smaller ${styles.filter} ${activeClass}`}
      onClick={() => updateActiveProjects(slug)}
    >
      <LineLink content={{ text: title }} customStyle={activeClass} />
    </h5>
  )
}

export default function Data({ content, locale }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          projects: allMarkdownRemark(
            filter: { frontmatter: { en: { templateKey: { eq: "project" } } } }
            sort: { fields: frontmatter___en___order, order: ASC }
          ) {
            nodes {
              frontmatter {
                en {
                  order
                  slug
                  title
                  location
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                  projectCats
                }
                zh {
                  order
                  slug
                  title
                  location
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                  projectCats
                }
              }
            }
          }
          projectCats: allMarkdownRemark(
            filter: {
              frontmatter: { en: { templateKey: { eq: "projectCat" } } }
            }
            sort: { fields: frontmatter___en___order, order: ASC }
          ) {
            nodes {
              frontmatter {
                en {
                  order
                  slug
                  title
                }
                zh {
                  order
                  slug
                  title
                }
              }
            }
          }
        }
      `}
      render={(data) => <Block content={content} data={data} locale={locale} />}
    />
  )
}
