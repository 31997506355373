import React from 'react'
import ImageWrap from 'components/ImageWrap'
import Video from 'components/Video'
import Inview from 'components/Inview'
import MarkdownWrap from 'components/MarkdownWrap'
import ButtonLink from 'components/ButtonLink'
import { useInView } from 'react-intersection-observer'
import * as styles from './styles.module.scss'

const Block = ({ pageId, content }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
  })
  const { pretitle, title, body, imageMobile, video, buttonLink } = content
  let pageClass = styles.map
  // if (pageId === '4d2b2f7c-374e-5a73-8efe-f5c67b0e0700') {
  //   pageClass = styles.map
  // }
  return (
    <div className={`${pageClass} block pad-v-standard`}>
      <div className='container'>
        <Inview className={`${styles.title} fade-in up`}>
          {pretitle && <h5>{pretitle}</h5>}
          <h2>{title}</h2>
        </Inview>
        {body && (
          <Inview className={`body centered fade-in up`}>
            <MarkdownWrap body={body} />
          </Inview>
        )}
        <div ref={ref}>
          <Inview className={`grid-24 ${styles.video} fade-in up`}>
            <Video content={video} loop={false} playing={inView} />
          </Inview>
          <Inview className={`grid-24 ${styles.image} fade-in up`}>
            <ImageWrap image={imageMobile} />
          </Inview>
        </div>
        {buttonLink && (
          <Inview className={`button-wrap centered fade-in up`}>
            <ButtonLink content={buttonLink} />
          </Inview>
        )}
      </div>
    </div>
  )
}

export default Block
