import React from 'react'
import Inview from 'components/Inview'
import ContactForm from 'components/Forms/Contact'
// import * as styles from './styles.module.scss'

const Block = ({ locale }) => {
  return (
    <Inview className='block pad-v-standard fade-in up'>
      <div className='container'>
        <ContactForm locale={locale} />
      </div>
    </Inview>
  )
}

export default Block
