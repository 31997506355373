import React from 'react'
import ImageWrap from 'components/ImageWrap'
import MarkdownWrap from 'components/MarkdownWrap'
import ButtonLink from 'components/ButtonLink'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Block = ({ content }) => {
  const { pretitle, title, body, image, buttonLink } = content || {}
  return (
    <Inview className={`block pad-v-standard no-collapse ${styles.component}`}>
      <div className={`container grid-12 fade-in up  ${styles.grid}`}>
        <div className={styles.text}>
          {pretitle && <h5>{pretitle}</h5>}
          <h2>{title}</h2>
          <div className='body justified'>
            <MarkdownWrap body={body} />
          </div>
          {buttonLink && (
            <Inview className={`button-wrap fade-in up`}>
              <ButtonLink content={buttonLink} />
            </Inview>
          )}
        </div>
        <div className={styles.image}>
          <ImageWrap image={image} />
        </div>
      </div>
    </Inview>
  )
}

export default Block
