import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Slideshow from 'components/Slideshows/CenteredHoverSlideshow'
import Inview from 'components/Inview'
import MarkdownWrap from 'components/MarkdownWrap'
import ButtonLink from 'components/ButtonLink'
import * as styles from './styles.module.scss'

const Block = ({ content, data, locale }) => {
  const { pretitle, title, body, buttonLink } = content
  const azotels = data?.azotels?.nodes
  const azotelsLocale = azotels?.map((item) => item.frontmatter[locale])

  const slideshow = {
    items: azotelsLocale.map((item) => {
      return {
        title: item.title,
        image: item.image,
        logo: item.logo,
      }
    }),
  }

  return (
    <div
      className={`${styles.component} block pad-v-standard no-collapse bg-dark`}
    >
      <div className='container'>
        <Inview className={`${styles.title} fade-in up`}>
          {pretitle && <h5>{pretitle}</h5>}
          <h2>{title}</h2>
        </Inview>
        {body && (
          <Inview className={`body centered fade-in up`}>
            <MarkdownWrap body={body} />
          </Inview>
        )}
      </div>
      <div className={styles.slideshowWrap}>
        <Slideshow content={slideshow} />
      </div>
      <div className='container'>
        {buttonLink && (
          <Inview className='button-wrap margin-top-more centered fade-in up'>
            <ButtonLink content={buttonLink} isBgDark={true} />
          </Inview>
        )}
      </div>
    </div>
  )
}

export default function Data({ content, locale }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          azotels: allMarkdownRemark(
            filter: { frontmatter: { en: { templateKey: { eq: "azotel" } } } }
            sort: { fields: frontmatter___en___order, order: ASC }
          ) {
            nodes {
              frontmatter {
                en {
                  order
                  slug
                  title
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                  logo {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                zh {
                  order
                  slug
                  title
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                  logo {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => <Block content={content} data={data} locale={locale} />}
    />
  )
}
