import React, { useState } from 'react'
import ArrowLong from 'components/ArrowLong'
import ImageWrap from 'components/ImageWrap'
import LinkWrap from 'components/LinkWrap'
import Inview from 'components/Inview'
import SwiperCore, {
  Autoplay,
  Navigation,
  EffectFade,
  Pagination,
} from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import * as styles from './styles.module.scss'

SwiperCore.use([Autoplay, Navigation, EffectFade, Pagination])

const Slideshow = ({ content, locale, isCMS }) => {
  const { items } = content

  if (!isCMS) {
    return (
      <div className={styles.component}>
        <SwiperImages items={items} locale={locale} />
      </div>
    )
  }
  if (isCMS) {
    return (
      <div className='container'>
        <code>
          Add entries to <a href='/admin/#/collections/post'>Posts</a> and they
          will appear here in reverse date order (with filter if selected).
        </code>
      </div>
    )
  }
}

const SwiperImages = ({ items, locale }) => {
  return (
    <Inview className='fade-in up'>
      <div className={`container ${styles.swiperNav}`}>
        <div className={styles.inner}>
          <div className='swiper-prev'>
            <ArrowLong direction='left' color='white' />
          </div>
          <div className='swiper-next'>
            <ArrowLong color='white' />
          </div>
        </div>
      </div>
      <Swiper
        navigation={{
          prevEl: '.swiper-prev',
          nextEl: '.swiper-next',
        }}
        pagination={{
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
        }}
        loop={true}
        loopAdditionalSlides={1}
        centeredSlides={true}
        speed={600}
        autoplay={{
          delay: 5000,
        }}
        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          601: {
            slidesPerView: 2,
          },
          1025: {
            slidesPerView: 3,
          },
        }}
      >
        {items?.map((item, i) => (
          <SwiperSlide key={i}>
            {({ isActive }) => (
              <Slide content={item} locale={locale} isActive={isActive} />
            )}
          </SwiperSlide>
        ))}
      </Swiper>
      <div className={`swiper-pagination`} />
    </Inview>
  )
}

const Slide = ({ content, isActive }) => {
  const { image, logo, link } = content
  // const isActiveClass = isActive ? styles.isActive : ''
  const isActiveClass = ''
  return (
    <LinkWrap className={`${styles.slide} ${isActiveClass}`} to={link}>
      <div className={styles.image}>
        <ImageWrap image={image} aspectRatio={3 / 2} />
      </div>
      <div className={styles.logo}>
        <ImageWrap image={logo} aspectRatio={3 / 2} />
      </div>
    </LinkWrap>
  )
}

export default Slideshow
