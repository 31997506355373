import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Slideshow from 'components/Slideshows/CenteredHoverSlideshow'
import * as styles from './styles.module.scss'

const Block = ({ content, data, locale }) => {
  const { pretitle, title, body, buttonLink } = content
  const azotels = data?.azotels?.nodes
  const azotelsLocale = azotels?.map((item) => item.frontmatter[locale])

  const slideshow = {
    items: azotelsLocale.map((item) => {
      return {
        title: item.title,
        image: item.image,
        logo: item.logo,
      }
    }),
  }

  return (
    <div className={`block pad-v-standard bg-dark`}>
      <Slideshow content={slideshow} />
    </div>
  )
}

export default function Data({ content, locale }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          azotels: allMarkdownRemark(
            filter: { frontmatter: { en: { templateKey: { eq: "azotel" } } } }
            sort: { fields: frontmatter___en___order, order: ASC }
          ) {
            nodes {
              frontmatter {
                en {
                  order
                  slug
                  title
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                  logo {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                zh {
                  order
                  slug
                  title
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                  logo {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => <Block content={content} data={data} locale={locale} />}
    />
  )
}
