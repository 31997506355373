import React from 'react'
import ImageWrap from 'components/ImageWrap'
import MarkdownWrap from 'components/MarkdownWrap'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Block = ({ content, isCirclePhoto = false, isBiggerTitle = false }) => {
  const { pretitle, title, body, image, subtitle, subtitle2 } = content || {}
  const isCirclePhotoClass = isCirclePhoto ? styles.isCircle : ''
  return (
    <Inview className='pad-v-standard first-add-v-pad fade-in up'>
      <div className={`container grid-24 ${styles.grid}`}>
        <div className={styles.text}>
          {pretitle && isBiggerTitle && <h4>{pretitle}</h4>}
          {pretitle && !isBiggerTitle && <h5>{pretitle}</h5>}
          <h2>{title}</h2>
          <div className={`border smaller left ${styles.border}`} />
          {isCirclePhoto && (
            <div className={styles.captionMobile}>
              {subtitle && <h6>{subtitle}</h6>}
              {subtitle2 && <h6>{subtitle2}</h6>}
            </div>
          )}

          <div className={`body justified ${styles.body}`}>
            <MarkdownWrap body={body} />
          </div>
        </div>
        <div className={styles.image}>
          <div className={isCirclePhotoClass}>
            <ImageWrap image={image} />
          </div>
          {isCirclePhoto && (
            <div className={styles.caption}>
              <h5 className='bold'>{title}</h5>
              {subtitle && <h6>{subtitle}</h6>}
              {subtitle2 && <h6>{subtitle2}</h6>}
            </div>
          )}
        </div>
      </div>
    </Inview>
  )
}

export default Block
