import React, { useState } from 'react'
import ArrowLong from 'components/ArrowLong'
import ImageWrap from 'components/ImageWrap'
import LinkWrap from 'components/LinkWrap'
import Inview from 'components/Inview'
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import * as styles from './styles.module.scss'

SwiperCore.use([Autoplay, Navigation, Pagination])

const Slideshow = ({ content }) => {
  const { items } = content
  const [textIndex, setTextIndex] = useState(0)
  const handleSlideChange = (swiper) => {
    setTextIndex(swiper.realIndex)
  }

  return (
    <Inview className={`${styles.swiperComponent} fade-in up`}>
      <div className='container'>
        <div className={styles.containerInner}>
          <SwiperImages items={items} handleSlideChange={handleSlideChange} />
          <SwiperText items={items} textIndex={textIndex} />
        </div>
      </div>
    </Inview>
  )
}

const SwiperImages = ({ items, handleSlideChange }) => {
  return (
    <div className={styles.swiperImages}>
      <Swiper
        onSlideChange={handleSlideChange}
        navigation={{
          prevEl: '.swiper-prev',
          nextEl: '.swiper-next',
        }}
        pagination={{
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
        }}
        loop={true}
        loopAdditionalSlides={items.length - 1}
        centeredSlides={true}
        speed={600}
        autoplay={{
          delay: 5000,
        }}
        spaceBetween={'10%'}
        touchEventsTarget='container'
        breakpoints={{
          0: {
            slidesPerView: 2,
          },
          601: {
            slidesPerView: 3,
          },
          1025: {
            slidesPerView: 4,
          },
        }}
      >
        {items?.map((item, i) => (
          <SwiperSlide key={i}>
            {({ isActive }) => (
              <SlideImage content={item} isActive={isActive} />
            )}
          </SwiperSlide>
        ))}
        <div className={styles.swiperNav}>
          <div className={styles.inner}>
            <div className='swiper-prev'>
              <ArrowLong direction='left' />
            </div>
            <div className='swiper-next'>
              <ArrowLong />
            </div>
          </div>
        </div>
      </Swiper>
    </div>
  )
}

const SwiperText = ({ items, textIndex }) => {
  let maxLength = 0
  let placeholderSlide = items[0]
  items.forEach((item) => {
    const thisLength = item.subtitle?.length + item.subtitle2?.length
    if (thisLength > maxLength) {
      maxLength = thisLength
      placeholderSlide = item
    }
  })

  return (
    <div className={styles.swiperText}>
      {items?.map((item, i) => {
        const isActive = i === textIndex
        return <SlideText content={item} isActive={isActive} key={i} />
      })}
      <SlideText content={placeholderSlide} isPlaceholder={true} />
      <div className='swiper-pagination' />
    </div>
  )
}

const SlideImage = ({ content, isActive }) => {
  const { image, link } = content
  const isActiveClass = isActive ? styles.isActive : ''
  return (
    <LinkWrap className={`${styles.slideImage} zoom-parent`} to={link}>
      <div
        className={`${styles.slideImageInner}  ${isActiveClass} zoom smaller`}
      >
        <ImageWrap image={image} aspectRatio={1} />
      </div>
    </LinkWrap>
  )
}

const SlideText = ({ content, isActive, isPlaceholder }) => {
  const { pretitle, title, subtitle, subtitle2, link, addTitleBorder } = content
  const isActiveClass = isActive ? styles.isActive : ''
  const isPlaceholderClass = isPlaceholder ? styles.isPlaceholder : ''
  const addTitleBorderClass = addTitleBorder ? styles.addTitleBorder : ''
  return (
    <LinkWrap
      className={`${styles.slideText} ${isActiveClass} ${isPlaceholderClass}`}
      to={link}
    >
      {pretitle && <h6>{pretitle}</h6>}
      <h5 className={`bold ${styles.title} ${addTitleBorderClass}`}>{title}</h5>
      {subtitle && <h6>{subtitle}</h6>}
      {subtitle2 && <h6>{subtitle2}</h6>}
    </LinkWrap>
  )
}

export default Slideshow
