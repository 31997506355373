import React from 'react'
import HeaderVersionWrap from 'components/HeaderVersionWrap'
import ImageWrap from 'components/ImageWrap'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Block = ({ content, layoutProps }) => {
  const { image } = content

  return (
    <div className='block bg-dark'>
      <HeaderVersionWrap layoutProps={layoutProps} isWhite={true}>
        <div className='pad-v-standard top-only'>
          <Inview className='container grid-12 fade-in up'>
            <div className={styles.image}>
              <ImageWrap image={image} />
            </div>
          </Inview>
        </div>
      </HeaderVersionWrap>
    </div>
  )
}

export default Block
