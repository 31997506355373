// extracted by mini-css-extract-plugin
export var addTitleBorder = "styles-module--add-title-border--kBYpw";
export var containerInner = "styles-module--container-inner--SIajI";
export var inner = "styles-module--inner--NY0sg";
export var isActive = "styles-module--is-active--6Zl0Z";
export var isPlaceholder = "styles-module--is-placeholder--m27C2";
export var slideImage = "styles-module--slide-image--Z-PFI";
export var slideImageInner = "styles-module--slide-image-inner--uuDb7";
export var slideText = "styles-module--slide-text--4b40+";
export var slideTextInner = "styles-module--slide-text-inner--K0XD4";
export var swiperComponent = "styles-module--swiper-component---Xbgu";
export var swiperImages = "styles-module--swiper-images--E7LCj";
export var swiperNav = "styles-module--swiper-nav--wwX+m";
export var swiperText = "styles-module--swiper-text--vEoIl";
export var title = "styles-module--title--sbdUH";