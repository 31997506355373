// extracted by mini-css-extract-plugin
export var arrow = "styles-module--arrow--yW8VT";
export var border = "styles-module--border--K7w-7";
export var component = "styles-module--component--cxPu7";
export var image = "styles-module--image--LL9MJ";
export var loaded = "styles-module--loaded--jR+6R";
export var logo = "styles-module--logo--7MLbR";
export var scrollDown = "styles-module--scroll-down--tiO4E";
export var text = "styles-module--text--IvB9M";
export var video = "styles-module--video--TXlu2";
export var wrap = "styles-module--wrap--2AG7v";