import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import CenteredPaddedSlideshow from 'components/Slideshows/CenteredPaddedSlideshow'
import ButtonLink from 'components/ButtonLink'
import * as styles from './styles.module.scss'

const Block = ({ content, data, locale }) => {
  const partners = data?.partners?.nodes
  const partnersLocale = partners?.map((item) => item.frontmatter[locale])

  const slideshow = {
    items: partnersLocale.map((item) => {
      return {
        title: item.name,
        subtitle: item.jobTitle,
        subtitle2: item.jobTitle2,
        image: item.image,
        link: `/partners/${item.slug}/`,
        // addTitleBorder: item.isDeceased,
      }
    }),
  }

  return (
    <div className='pad-v-standard'>
      <CenteredPaddedSlideshow content={slideshow} />
      <div className='button-wrap centered'>
        {content.buttonLink.text && <ButtonLink content={content.buttonLink} />}
      </div>
    </div>
  )
}

export default function Data({ content, locale }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          partners: allMarkdownRemark(
            filter: { frontmatter: { en: { templateKey: { eq: "partner" } } } }
            sort: { fields: frontmatter___en___order, order: ASC }
          ) {
            nodes {
              frontmatter {
                en {
                  order
                  slug
                  name
                  jobTitle
                  jobTitle2
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                zh {
                  order
                  slug
                  name
                  jobTitle
                  jobTitle2
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => <Block content={content} data={data} locale={locale} />}
    />
  )
}
