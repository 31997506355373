import React from 'react'
import ImageWrap from 'components/ImageWrap'
import Inview from 'components/Inview'
import MarkdownWrap from 'components/MarkdownWrap'
import ButtonLink from 'components/ButtonLink'
import * as styles from './styles.module.scss'

const Block = ({ content }) => {
  const { pretitle, title, body, image, buttonLink } = content
  return (
    <div className='block pad-v-standard'>
      <div className='container'>
        <Inview className={`${styles.title} fade-in up`}>
          {pretitle && <h5>{pretitle}</h5>}
          <h2>{title}</h2>
        </Inview>
        {body && (
          <Inview className={`body centered fade-in up`}>
            <MarkdownWrap body={body} />
          </Inview>
        )}
        {buttonLink && (
          <Inview className={`button-wrap centered fade-in up`}>
            <ButtonLink content={buttonLink} />
          </Inview>
        )}
      </div>
    </div>
  )
}

export default Block
