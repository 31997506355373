import React, { useState, useEffect } from 'react'
import HeaderHiddenWrap from 'components/HeaderHiddenWrap'
import HeaderVersionWrap from 'components/HeaderVersionWrap'
import ParallaxWrapBanner from 'components/ParallaxWrapBanner'
import ImageWrap from 'components/ImageWrap'
import Video from 'components/Video'
import LogoSvg from 'assets/svg/vi/logo.svg'
import * as styles from './styles.module.scss'

const Block = ({ content, locale, layoutProps }) => {
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    setIsLoaded(true)
    // window.addEventListener('resize', getVideoUrl)
    // return () => window.removeEventListener('resize', getVideoUrl)
  }, [])

  const scrollDown = (e) => {
    const block = e.target.closest('.block')
    window.scrollTo({
      top: block.offsetHeight,
      behavior: 'smooth',
    })
  }

  const { subtitle, buttonText, video, imageMobile } = content
  const isLoadedClass = isLoaded ? styles.loaded : ''
  return (
    <div className={`block ${styles.component} ${isLoadedClass}`}>
      <HeaderHiddenWrap layoutProps={layoutProps}>
        <HeaderVersionWrap layoutProps={layoutProps} isWhite={true} addClass='home'>
          <ParallaxWrapBanner>
            <div className={styles.video}>
              <Video content={video} />
            </div>
            <div className={styles.image}>
              <ImageWrap image={imageMobile} />
            </div>
          </ParallaxWrapBanner>
          <div className={styles.text}>
            <div className={styles.wrap}>
              <div className={styles.logo}>
                <LogoSvg />
              </div>
              <div className={styles.border} />
              <h5>{subtitle}</h5>
            </div>
          </div>
          <div className={styles.scrollDown} onClick={scrollDown}>
            <h5>{buttonText}</h5>
            <div className={styles.arrow} />
          </div>
        </HeaderVersionWrap>
      </HeaderHiddenWrap>
    </div>
  )
}

export default Block
