import React, { useEffect, useState } from 'react'
import { InView } from 'react-intersection-observer'
import * as styles from './styles.module.scss'

const Component = ({ children }) => {
  // const [isInView, setIsInView] = useState(false)
  // const handleChange = (inView, entry) => {
  //   setIsInView(inView)
  // }
  // const inViewClass = isInView ? styles.markInView : ''

  const parallax = React.useRef(null)

  useEffect(() => {
    const scrollHandler = () => {
      const element = parallax.current
      if (element) {
        const parent = element.closest('.block')
        if (parent) {
          const scrollPos = window.scrollY
          const yVal = scrollPos * 0.5
          const percent = window.scrollY / parent.offsetHeight
          element.style.transform = `translateY(${yVal}px)`
          element.style.opacity = 1 - percent
        }
      }
    }
    window.addEventListener('scroll', scrollHandler)
    return () => window.removeEventListener('scroll', scrollHandler)
  }, [])

  return (
    <div className={styles.component} ref={parallax}>
      {/* <InView onChange={handleChange} threshold={0.5} triggerOnce={true}> */}
      {children}
      {/* </InView> */}
    </div>
  )
}

export default Component
